import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // ocupa tota l'alçada de la pantalla
      }}
    >
      <CircularProgress size={64} />
    </Box>
  );
};

export default Loading;
