import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Generator from "./views/Generator";
import Validator from "./views/Validator";
import Login from "./views/Login";
import PageNotFound from "./views/PageNotFound";
import Profile from "./views/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

// styles
import "./App.css";
import { getConfig } from "./config";
import axios from "axios";

const MAX_RETRIES = 3;

const App = () => {
  const { 
    isLoading,
    isAuthenticated,
    error, 
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    logout
  } = useAuth0();
  const [hasAccess, setHasAccess] = useState(false);
  const { apiOrigin } = getConfig();
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);

  const authUser = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/user/auth`, {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const authorized = await response.json(); 

      if (authorized===false) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          }
        });
      } else {
        setHasAccess(true);
      }
    } catch (err) {
      console.log("ERR", err);
      if (err.error === 'login_required') {
        loginWithRedirect();
      } else {
        console.error("Auth error: ", err);
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          }
        });
      }
    }
  };

  useEffect(() => {
    if (countries.length === 0 & languages.length === 0) {
      getAccessTokenSilently().then((token) =>{
        axios.get(`${apiOrigin}/data`, {headers: {
            Authorization: `Bearer ${token}`}
          }
        ).then((response) => {
          setLanguages(response.data.languages);
          setCountries(response.data.countries);
        }).catch((err) => {
          console.log("ERR", err)
        })
      })
    }
  }, [countries, languages, apiOrigin]);

  useEffect(() => {
    if (isAuthenticated && user) {
      authUser();
    } else if (!isAuthenticated && !isLoading) {
      // Si no està autenticat i no està carregant, força el login
      loginWithRedirect();
    }
  }, [isAuthenticated, user, isLoading]);

  const handleAuthError = async () => {
    const retries = Cookies.get('auth_retries') || 0;

    if (error && error.error === 'invalid_state') {
      if (retries < MAX_RETRIES) {
        Cookies.set('auth_retries', parseInt(retries) + 1);
        await loginWithRedirect();
      } else {
        Cookies.remove('auth_retries');
        logout({ returnTo: window.location.origin });
      }
    } else {
      Cookies.remove('auth_retries');
    }
  };

  useEffect(() => {
    if (error) {
      handleAuthError();
    }
  }, [error])

  if (isLoading || languages.length === 0 || countries.length === 0) {
    return <Loading />;
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <div id="app" className="d-flex flex-column h-100">
          {isAuthenticated & hasAccess? (
            <NavBar>
              <Routes>
                <Route path="/" element={<Generator countries={countries} languages={languages}/>} />
                <Route path="/generate" element={<Generator countries={countries} languages={languages}/>} />
                <Route path="/validate" element={<Validator countries={countries} languages={languages}/>} />
                <Route path="/profile" element={<Profile/>} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </NavBar>
          ):
          (
            <Routes>
              <Route path="/" element={<Login/>} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          )}
          <Footer />
        </div>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;
