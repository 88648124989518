import React, { useState } from 'react';
import { 
  Autocomplete,
  Container,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../config";
import { useSnackbar } from 'notistack';
import * as Sentry from "@sentry/react";
import DisplayGenerator from '../components/DisplayGenerator';


const LabelGenerationComponent = ({countries, languages}) => {
  const {
    getAccessTokenSilently,
  } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const [productCode, setProductCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const { apiOrigin } = getConfig();
  const [loadingDrive, setLoadingDrive] = useState(false);
  const [loadingFrases, setLoadingFrases] = useState(false);
  const [loadingGenerar, setLoadingGenerar] = useState(false);
  const [etiqueta, setEtiqueta] = useState([]);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [inputLangValue, setInputLangValue] = useState("");
  const [inputCountValue, setInputCountValue] = useState('');

  const handleProductCodeChange = (event) => {
    setProductCode(event.target.value);
  };

  const handleInputLangChange = (event, newInputValue) => {
    setInputLangValue(newInputValue);
  };

  const handleLangKeyDown = (event) => {
    if (event.key === 'Enter') {
      const firstOption = languages.find(language =>
        language.toLowerCase().includes(inputLangValue.toLowerCase())
      );

      if (firstOption) {
        setSelectedLanguages((prevSelected) => {
          if (!prevSelected.includes(firstOption)) {
            return [...prevSelected, firstOption];
          }
          return prevSelected;
        });
        event.preventDefault();
      }
    }
  };

  const handleInputCountChange = (event, newInputValue) => {
    setInputCountValue(newInputValue);
  };

  const handleCountKeyDown = (event) => {
    if (event.key === 'Enter') {
      const firstOption = countries.find(country =>
        country.toLowerCase().includes(inputCountValue.toLowerCase())
      );

      if (firstOption) {
        setSelectedCountry(firstOption);  
        event.preventDefault(); 
      }
    }
  };


  const driveAPI = async () => {
    enqueueSnackbar("Leyendo tablas de Drive. Esto puede tardar hasta un minuto...", { variant: 'info' });
    setLoadingDrive(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/drive`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        enqueueSnackbar('No se han podido cargar las tablas del Drive', { variant: 'error' });
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      } else {
        enqueueSnackbar('Tablas cargadas correctamente!', { variant: 'success' });
      }
      setLoadingDrive(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      Sentry.captureException(error);
      enqueueSnackbar('Ha ocurrido un error leyendo las tablas de Drive, contacta con el administrador.', { variant: 'error' });
      setLoadingDrive(false);
    }
  };

  const frasesAPI = async () => {
    enqueueSnackbar("Descargando Frases HP de internet y guradando en Drive. Esto puede tardar hasta tres minutos...", { variant: 'info' });
    setLoadingFrases(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/frases`, {
        method: "POST",
        body: JSON.stringify({
          languages: languages
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const responseData = await response.json();
      console.log("Response", responseData)
      if (responseData.data.errors.length){
        responseData.data.errors.forEach((err) => {
          enqueueSnackbar(err, { variant: 'error' });
        })
        enqueueSnackbar('Frases copiadas con errores. Revisar Drive', { variant: 'warning' });
      } else {
        enqueueSnackbar('Frases copiadas correctamente!', { variant: 'success' });
      }
      setLoadingFrases(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      Sentry.captureException(error);
      enqueueSnackbar('Ha ocurrido un error descargando las Frases HP de internet, contacta con el administrador.', { variant: 'error' });
      setLoadingFrases(false);
    }
  };

  const generateAPI = async () => {
    enqueueSnackbar("Generando etiqueta...", { variant: 'info' });
    setLoadingGenerar(true);
    setEtiqueta([]);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiOrigin}/generate`, {
        method: "POST",
        body: JSON.stringify({
          code: productCode,
          country: selectedCountry,
          languages: selectedLanguages,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const responseData = await response.json();
      if (responseData.data.length === 0) {
        enqueueSnackbar('No se ha podido generar la etiqueta pruébalo más tarde.', { variant: 'error' });
        throw new Error(`Error: ${responseData.errors}`);
      }
      else {
        setErrors(responseData.data.errors);
        setWarnings(responseData.data.warnings);
        setEtiqueta(responseData.data.content);
        console.log("Respuesta del backend:", responseData);
        enqueueSnackbar('Etiqueta generada correctamente!', { variant: 'success' });
      }
      setLoadingGenerar(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      Sentry.captureException(error);
      enqueueSnackbar('Ha ocurrido un error generando la etiqueta, contacta con el administrador.', { variant: 'error' });
      setLoadingGenerar(false);
    }
  };

  return (
    <Container tabIndex="0" sx={{width:"1000px", paddingTop: "64px"}}>
      <Box className="main">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
          Generación de contenido de etiquetas
        </Typography>

        <Box display="flex" justifyContent="right" my={3}>
          <Button variant="outlined"
                  color="primary" 
                  disabled={loadingDrive}
                  onClick={driveAPI}
                  sx={{width: 240,
                       fontSize: "1rem",
                       fontWeight: 600,
                       borderRadius: "0.5rem",
                       textTransform: 'none'}}>
            Leer Tablas Drive
          </Button>
        </Box>
        <Box display="flex" justifyContent="right" my={3}>
          <Button variant="outlined"
                  color="primary" 
                  disabled={loadingFrases}
                  onClick={frasesAPI}
                  sx={{width: 240,
                       fontSize: "1rem",
                       fontWeight: 600,
                       borderRadius: "0.5rem",
                       textTransform: 'none'}}>
            Actualizar Frases HP en Drive
          </Button>
        </Box>

        <Box my={3}>
          <Typography variant="body1" sx={{marginBottom: "24px"}}>1. Introduzca el código de producto asociado</Typography>
          <TextField
            fullWidth
            size="small"
            placeholder=" "  // Un espai per evitar que es mostri text.
            InputLabelProps={{ shrink: false }}
            value={productCode}
            onChange={handleProductCodeChange}
          />
        </Box>

        <Box my={3}>
          <Typography variant="body1" sx={{marginBottom: "24px"}}>2. Seleccione el país para el cual quiere generar la etiqueta</Typography>
          <Autocomplete
            options={countries}
            size="small"
            getOptionLabel={(option) => option}
            value={selectedCountry}
            inputValue={inputCountValue}
            onInputChange={handleInputCountChange}
            onChange={(event, newValue) => {
              setSelectedCountry(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder=" " InputLabelProps={{ shrink: false }} variant="outlined" onKeyDown={handleCountKeyDown} />
            )}
          />
        </Box>

        <Box my={3}>
          <Typography variant="body1" sx={{marginBottom: "24px"}}>3. Seleccione los idiomas que deben aparecer en la etiqueta</Typography>
          <Autocomplete
            multiple
            size="small"
            id="languages"
            options={languages}
            disableCloseOnSelect
            filterSelectedOptions
            inputValue={inputLangValue}
            onInputChange={handleInputLangChange}
            getOptionLabel={(option) => option}
            value={selectedLanguages}
            onChange={(event, newValue) => {
              setSelectedLanguages(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params} 
                placeholder=" " 
                InputLabelProps={{ shrink: false }} 
                variant="outlined" 
                onKeyDown={handleLangKeyDown}
              />
            )}
          />
        </Box>

        <Box display="flex" justifyContent="right" my={3}>
        <Button
          variant="outlined"
          color="primary"
          sx={{width: 200, fontSize: "1rem", fontWeight: 600, borderRadius: "0.5rem", textTransform: 'none'}}
          onClick={generateAPI}>
            Generar Contenido
          </Button>
        </Box>
        {loadingGenerar ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : null}
        {etiqueta.length !== 0 ? (
          <DisplayGenerator 
            code={productCode}
            languages={selectedLanguages}
            country={selectedCountry}
            etiqueta={etiqueta}
            errors={errors}
            warnings={warnings} />
        ) : null}
      </Box>
    </Container>
  );
};

export default LabelGenerationComponent;
