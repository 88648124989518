import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../assets/LogoInquide.png'; // Canvia aquest camí al logotip

const Home = () => {
  const { loginWithRedirect } = useAuth0(); // Per gestionar el login

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
      }}
    >
      {/* Logotip centrat */}
      <Box component="img" src={logo} alt="Logo" sx={{ width: "15%", height: 'auto', marginBottom: 4 }} />
      
      {/* Text informatiu */}
      <Typography variant="h4" sx={{ fontWeight: 600, textAlign: 'center', marginBottom: 4 }}>
        Generación y Validación de etiquetas
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: 6, maxWidth: '600px' }}>
        Esta herramienta permite generar i validar etiquetas de productos. Por favor, inicia sesión para acceder a dichas funcionalidades.
      </Typography>

      {/* Botó de login */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => loginWithRedirect()}
        sx={{
          fontSize: '1rem',
          fontWeight: 600,
          padding: '12px 24px',
          borderRadius: '8px',
        }}
      >
        Iniciar sesión
      </Button>
    </Box>
  );
};

export default Home;
