import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid2';
import { getConfig } from '../config';
import ExportExcelButton from './ExportExcel';

const DisplayGenerator = ({etiqueta, errors, warnings, code, languages, country}) => {
  const { data_mapping, sorted_cols } = getConfig();
  console.log(etiqueta);
  return (
    <Grid container spacing={2} sx={{marginBottom: "64px"}}>
      {/* Primera fila */}
      <Grid item size={8}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>Contenido para la etiqueta del producto {code}</Typography>
      </Grid>
      <Grid item size={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ExportExcelButton data={etiqueta} code={code}/>
      </Grid>

      {/* Segona fila: Taula */}
      <Grid item size={12}>
        <TableContainer component={Paper} sx={{
        boxShadow: 'none',
        border: '1px solid rgba(38, 39, 48, 0.1)',
        borderRadius: '0.5rem'
        }}>
            <Table size="small">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#fafafa' }}> {/* Capçalera amb fons clar */}
                        <TableCell sx={{ fontWeight: 'bold', borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>Referencia</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>País</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Idiomas</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>{code}</TableCell>
                        <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>{country}</TableCell>
                        <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }}}>{languages.join(", ")}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
      </Grid>
      <Grid item size={12}>
        <Accordion expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Ver errores y avisos ({errors.length + warnings.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {errors.map((err, idx) => {
              return (<Typography
                key={idx}
                sx={{
                    backgroundColor: '#fdecea',
                    color: '#d32f2f',          
                    padding: '8px',            
                    borderRadius: '4px',       
                    marginBottom: '8px'        
                }}
                >
                {err}
                </Typography>)
            })}
            {warnings.map((warn, idx) => {
              return (<Typography
                key={idx}
                sx={{
                  backgroundColor: '#fff4e5',
                  color: '#ed6c02',          
                  padding: '8px',
                  borderRadius: '4px',
                  marginBottom: '8px'        
                }}
                >
                {warn}
                </Typography>)
            })}
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item size={12}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>Nombre comercial:</Typography> 
        {/* TODO Add nombre comercial! */}
      </Grid>
      <Grid item size={12}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>Campos generados:</Typography>
      </Grid>
      <Grid item size={12}>
        <TableContainer component={Paper} sx={{
            boxShadow: 'none',
            border: '1px solid rgba(38, 39, 48, 0.1)',
            borderRadius: '0.5rem'
            }}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#fafafa' }}> {/* Capçalera amb fons clar */}
                            <TableCell sx={{ fontWeight: 'bold'}}>Campo</TableCell>
                            {etiqueta.map((label, idx) => {
                              return (
                                <TableCell key={idx} sx={{ fontWeight: 'bold', borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>Valor {label.idioma}</TableCell>
                              )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {sorted_cols.map((col, col_idx) => {
                          return (<TableRow key={col_idx}>
                              <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}>{col}</TableCell>
                              {etiqueta.map((label, idx) => {
                              return (
                                <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>{label[data_mapping[col]]}</TableCell>
                              )
                            })}
                          </TableRow>)
                      })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
  );
};

export default DisplayGenerator;
