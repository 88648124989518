import React from 'react';
import { Button } from '@mui/material';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getConfig } from '../config';

const ExportExcelButton = ({ data, code, isValidation=false }) => {
    const { data_mapping, sorted_cols } = getConfig();

    const exportToExcel = async () => {
        // Crear un nou Workbook
        const workbook = new ExcelJS.Workbook();
        

        if (isValidation) {
            let header = ["Campo", "Valor Esperado", "Valor Etiqueta", "Estado"];
            data.forEach( async (label) => {
                const worksheet = workbook.addWorksheet(label.idioma);
                worksheet.addRow(header);
                header.forEach((value, c) =>{
                    worksheet.getCell(1, c + 1).border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                    worksheet.getCell(1, c + 1).alignment = { horizontal: 'center', vertical: 'top' };
                })

                worksheet.getRow(1).font = { bold: true };

                sorted_cols.forEach((col, col_idx) => {
                    if (col!=="Idioma"){
                        if (label[data_mapping[col]] !== undefined) {
                            const rowValues = [col,
                                label[data_mapping[col]].reference_value,
                                label[data_mapping[col]].label_value,
                                label[data_mapping[col]].revision === "CHECK" ? "Revisar" : label[data_mapping[col]].revision === "VALID" ? "Correcto" : "",
                            ];
                            const row = worksheet.addRow(rowValues);

                            const firstCell = row.getCell(1);
                            firstCell.font = { bold: true };
                            firstCell.alignment = { horizontal: 'center', vertical: 'top' };
                            firstCell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };

                            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                                if (colNumber > 1) {
                                    cell.alignment = { wrapText: true, vertical: 'top' };
                                }
                            });
                        }
                    }
                });
                worksheet.columns.forEach((column, idx) => {
                    if (idx > 0 & idx < 3) {
                        column.width = 90;
                    } else {
                        column.width = 40;
                    }
                });
            })
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            saveAs(blob, 'etiqueta_validada.xlsx');
        } else {
            const worksheet = workbook.addWorksheet(code);
            let header = ["Campo", ...data.map((label) => {
                return "Valor " + label.idioma;
            })];
            worksheet.addRow(header);
            header.forEach((value, c) =>{
                worksheet.getCell(1, c + 1).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                worksheet.getCell(1, c + 1).alignment = { horizontal: 'center', vertical: 'top' };
            })

            worksheet.getRow(1).font = { bold: true };

            sorted_cols.forEach((col, col_idx) => {
                const rowValues = [col, ...data.map((label) => label[data_mapping[col]])];
                const row = worksheet.addRow(rowValues);

                const firstCell = row.getCell(1);
                firstCell.font = { bold: true };
                firstCell.alignment = { horizontal: 'center', vertical: 'top' };
                firstCell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };

                row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                    if (colNumber > 1) {
                        cell.alignment = { wrapText: true, vertical: 'top' };
                    }
                });
            });
            worksheet.getColumn(1).width = 40; 
            worksheet.columns.forEach((column, idx) => {
                if (idx > 0) {
                    column.width = 90;
                }
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            saveAs(blob, 'etiqueta_generada.xlsx');
        }
    };

    return (
        <Button
            variant="outlined"
            color="primary"
            sx={{
                width: 200,
                fontSize: "1rem",
                fontWeight: 600,
                borderRadius: "0.5rem",
                textTransform: 'none'
            }}
            onClick={exportToExcel}>
            Descarregar Excel
        </Button>
    );
};

export default ExportExcelButton;
