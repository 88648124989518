import React, { useState } from 'react';
import { 
  Autocomplete,
  Container,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import * as Sentry from "@sentry/react";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { useSnackbar } from 'notistack';
import { getConfig } from '../config';
import { useAuth0 } from '@auth0/auth0-react';
import DisplayValidator from '../components/DisplayValidator';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


const LabelValidationComponent = ({countries, languages}) => {
  const {
    getAccessTokenSilently,
  } = useAuth0();
  const [productCode, setProductCode] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [front, setFront] = useState([]);
  const [back, setBack] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { apiOrigin } = getConfig();
  const [loadingValidar, setLoadingValidar] = useState(false);
  const [validation, setValidation] = useState([]);
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [inputLangValue, setInputLangValue] = useState('');
  const [inputCountValue, setInputCountValue] = useState('');

  const handleProductCodeChange = (event) => {
    setProductCode(event.target.value);
  };

  const handleInputLangChange = (event, newInputValue) => {
    setInputLangValue(newInputValue);
  };

  const handleLangKeyDown = (event) => {
    if (event.key === 'Enter') {
      const firstOption = languages.find(language =>
        language.toLowerCase().includes(inputLangValue.toLowerCase())
      );

      if (firstOption) {
        setSelectedLanguages((prevSelected) => {
          if (!prevSelected.includes(firstOption)) {
            return [...prevSelected, firstOption];
          }
          return prevSelected;
        });
        event.preventDefault();
      }
    }
  };

  const handleInputCountChange = (event, newInputValue) => {
    setInputCountValue(newInputValue);
  };

  const handleCountKeyDown = (event) => {
    if (event.key === 'Enter') {
      const firstOption = countries.find(country =>
        country.toLowerCase().includes(inputCountValue.toLowerCase())
      );

      if (firstOption) {
        setSelectedCountries((prevSelected) => {
          if (!prevSelected.includes(firstOption)) {
            return [...prevSelected, firstOption];
          }
          return prevSelected;
        });
        event.preventDefault();
      }
    }
  };

  const validateAPI = async () => {
    enqueueSnackbar("Validando etiqueta...", { variant: 'info' });
    setLoadingValidar(true);
    setValidation([]);
    try {
      const token = await getAccessTokenSilently();

      const formData = new FormData();

      // Afegir arxius al FormData
      if (front.length) {
        formData.append('front', front[0].file);  
      }
      if (back.length) {
        formData.append('back', back[0].file);  
      }

      // Afegir altres dades al FormData
      formData.append('code', productCode);
      formData.append('countries', JSON.stringify(selectedCountries));
      formData.append('languages', JSON.stringify(selectedLanguages));

      const response = await fetch(`${apiOrigin}/validate`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      const responseData = await response.json();
      if (responseData.data.length === 0) {
        enqueueSnackbar('No se ha podido validar la etiqueta pruébalo más tarde.', { variant: 'error' });
        throw new Error(`Error: ${responseData.errors}`);
      } else {
        console.log("Response", responseData);
        setErrors(responseData.data.errors);
        setWarnings(responseData.data.warnings);
        setValidation(responseData.data.validation);
        enqueueSnackbar('Etiqueta validada correctamente!', { variant: 'success' });
      }
      setLoadingValidar(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      Sentry.captureException(error);
      enqueueSnackbar('Ha ocurrido un error validando la etiqueta, contacta con el administrador.', { variant: 'error' });
      setLoadingValidar(false);
    }
  };


  return (
    <Container tabIndex="0" sx={{width:"1000px", paddingTop: "64px"}}>
      <Box className="main">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
          Validación de contenido de etiquetas
        </Typography>

        <Box my={3}>
          <Typography variant="body1" sx={{marginBottom: "24px"}}>1. Cargue el fichero PDF para la Validación del diseño pre-impresión de la etiqueta o el de la etiqueta impresa</Typography>
          <Typography variant="body2">Cara</Typography>
          <FilePond
            files={front}
            onupdatefiles={setFront}
            allowMultiple={false}
            maxFileSize="20MB"
            acceptedFileTypes={['application/pdf']}
            labelFileTypeNotAllowed="Solo se permiten archivos PDF"
            fileValidateTypeLabelExpectedTypes="Se espera un archivo PDF"
            labelIdle='Arrastra y suelta el fichero o clica para cargar'
          />
          <Typography variant="body2">Dorso (Opcional)</Typography>
          <FilePond
            files={back}
            onupdatefiles={setBack}
            allowMultiple={false}
            maxFileSize="20MB"
            acceptedFileTypes={['application/pdf']}
            labelFileTypeNotAllowed="Solo se permiten archivos PDF"
            fileValidateTypeLabelExpectedTypes="Se espera un archivo PDF"
            labelIdle='Arrastra y suelta el fichero o clica para cargar'
          />
        </Box>

        <Box my={3}>
          <Typography variant="body1" sx={{marginBottom: "24px"}}>2. Introduzca el código de producto asociado, obligatorio</Typography>
          <TextField
            fullWidth
            size="small"
            placeholder=" "  
            InputLabelProps={{ shrink: false }}
            value={productCode}
            onChange={handleProductCodeChange}
          />
        </Box>

        <Box my={3}>
          <Typography variant="body1" sx={{marginBottom: "24px"}}>3. Seleccione los países para los cuales quiere validar la etiqueta</Typography>
          <Autocomplete
            multiple
            size="small"
            id="countries"
            options={countries}
            disableCloseOnSelect
            inputValue={inputCountValue}
            filterSelectedOptions
            onInputChange={handleInputCountChange}
            getOptionLabel={(option) => option}
            value={selectedCountries}
            onChange={(event, newValue) => {
              setSelectedCountries(newValue);
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                placeholder=" " 
                InputLabelProps={{ shrink: false }} 
                variant="outlined" 
                onKeyDown={handleCountKeyDown}
              />
            )}
          />
        </Box>

        <Box my={3}>
          <Typography variant="body1" sx={{marginBottom: "24px"}}>4. Seleccione los idiomas que deben aparecer en la etiqueta</Typography>
          <Autocomplete
            multiple
            size="small"
            id="languages"
            options={languages}
            disableCloseOnSelect
            inputValue={inputLangValue}
            filterSelectedOptions
            onInputChange={handleInputLangChange}
            getOptionLabel={(option) => option}
            value={selectedLanguages}
            onChange={(event, newValue) => {
              setSelectedLanguages(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params} 
                placeholder=" " 
                InputLabelProps={{ shrink: false }} 
                variant="outlined" 
                onKeyDown={handleLangKeyDown}
              />
            )}
          />
        </Box>

        <Box display="flex" justifyContent="right" my={3}>
          <Button 
            variant="outlined"
            color="primary"
            sx={{width: 200, fontSize: "1rem", fontWeight: 600, borderRadius: "0.5rem", textTransform: 'none'}}
            onClick={validateAPI}>
            Validar Etiqueta
          </Button>
        </Box>
        {loadingValidar ? (
          <Box display="flex" justifyContent="center" my={3}>
            <CircularProgress />
          </Box>
        ) : null}
        {validation.length !== 0 ? (
          <DisplayValidator
            code={productCode}
            languages={selectedLanguages}
            countries={selectedCountries}
            validation={validation}
            errors={errors}
            warnings={warnings} />
        ) : null}
      </Box>
    </Container>
  );
};

export default LabelValidationComponent;