import configJson from "./auth_config.json";

export function getConfig() {
  const countries = process.env.COUNTRIES || "Alemania,Austria,Bulgaria";
  const languages = process.env.LANGUAGES || "Alemán,Búlgaro,Checo";
  const apiOrigin = process.env.ORIGIN || "";
  const data_mapping = {
    "Nombre producto": "producto_nombre",
    "Claims": "producto_tipo",
    "Nombre registrado": "producto_nombre_registro",
    "Descripción": "producto_descripcion",
    "Presentación del producto": "producto_presentacion",
    "Dosificación y modo de empleo": "producto_dosificacion_empleo",
    "Códigos H + EUH": "codigos_h",
    "Frases de peligro + EUH": "codigos_frases_h",
    "Frases de peligro + EUH según Registro": "frases_h",
    "Códigos P": "codigos_p",
    "Nº Cas": "numero_cas",
    "Consejos de prudencia": "codigos_frases_p",
    "Consejos de prudencia según Registro": "frases_p",
    "Recomendaciones intox": "frases_intoxicacion",
    "Frases de empleo, seguridad y utilización (España)": "frases_empleo_seguridad",
    "Composición + nº Cas": "producto_composicion",
    "Teléfono antiveneno": "telefono_antiveneno",
    "UFI": "numero_ufi",
    "Información medioambiental (Italia)": "frases_informacion_medioambiental_italia",
    "Palabra de advertencia": "palabra_advertencia",
    "Pictogramas": "pictogramas_seguridad",
    "Medida unidad": "producto_medida_unidad",
    "Medida envase": "producto_medida_envase",
    "Número de registro": "numero_registro",
    "Frase cloroliner": "frases_cloroliner",
    "Cuadro biocidas": "frases_biocidas",
    "Holder": "holder_company_name",
    "Direcciones": "holder_company_direccion",
    "Uso": "producto_uso",
    "Frases H/P": "frases_hp",
    "Idioma": "idioma",
    "Composición Armonizada": "composicion_armonizada",
    "Iconografia Dosificación": "dosification",
  };

  const sorted_cols = [
    "Idioma",
    "Nombre producto",
    "Nombre registrado",
    "Número de registro",
    "Uso",
    "Claims",
    "Descripción",
    "Presentación del producto",
    "Iconografia Dosificación",
    "Dosificación y modo de empleo",
    "Composición + nº Cas",
    "Nº Cas",
    "Composición Armonizada",
    "Medida unidad",
    "Medida envase",
    "Pictogramas",
    "Palabra de advertencia",
    "Frases H/P",
    "Códigos H + EUH",
    "Frases de peligro + EUH",
    "Frases de peligro + EUH según Registro",
    "Códigos P",
    "Consejos de prudencia",
    "Consejos de prudencia según Registro",
    "Frase cloroliner",
    "Recomendaciones intox",
    "Cuadro biocidas",
    "Teléfono antiveneno",
    "UFI",
    "Holder",
    "Direcciones",
    "Frases de empleo, seguridad y utilización (España)",
    "Información medioambiental (Italia)",
  ];

  return {
    countries: countries.split(",").map(country => country.trim()),
    languages: languages.split(",").map(country => country.trim()),
    data_mapping: data_mapping,
    sorted_cols: sorted_cols,
    apiOrigin: apiOrigin,
    domain: configJson.domain,
    clientId: configJson.clientId,
  };
}
