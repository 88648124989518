import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid2';
import { getConfig } from '../config';
import ExportExcelButton from './ExportExcel';

const DisplayValidator = ({validation, errors, warnings, code, languages, countries}) => {
  const { data_mapping, sorted_cols } = getConfig();
  console.log(validation);
  return (
    <Grid container spacing={2} sx={{marginBottom: "64px"}}>
      <Grid item size={8}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>Contenido para la validation del producto {code}</Typography>
      </Grid>
      <Grid item size={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ExportExcelButton data={validation} code={code} isValidation={true}/>
      </Grid>
      <Grid item size={12}>
        <TableContainer component={Paper} sx={{
        boxShadow: 'none', 
        border: '1px solid rgba(38, 39, 48, 0.1)', 
        borderRadius: '0.5rem'
        }}>
            <Table size="small">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#fafafa' }}> 
                        <TableCell sx={{ fontWeight: 'bold', borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>Referencia</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>Países</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Idiomas</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>{code}</TableCell>
                        <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderRight: '1px solid rgba(38, 39, 48, 0.1)' }}>{countries.join(", ")}</TableCell>
                        <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }}}>{languages.join(", ")}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
      </Grid>
      <Grid item size={12}>
        <Accordion expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Ver errores y avisos ({errors.length + warnings.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {errors.map((err, idx) => {
              return (<Typography
                key={idx}
                sx={{
                    backgroundColor: '#fdecea',
                    color: '#d32f2f',          
                    padding: '8px',            
                    borderRadius: '4px',       
                    marginBottom: '8px'        
                }}
                >
                {err}
                </Typography>)
            })}
            {warnings.map((warn, idx) => {
              return (<Typography
                key={idx}
                sx={{
                  backgroundColor: '#fff4e5',
                  color: '#ed6c02',          
                  padding: '8px',
                  borderRadius: '4px',
                  marginBottom: '8px'        
                }}
                >
                {warn}
                </Typography>)
            })}
          </AccordionDetails>
        </Accordion>
      </Grid>
      {validation.map((etiqueta) => {
        return (
          <>
            <Grid item size={12}>
              <Typography variant="subtitle1" >
                <span style={{ fontWeight: 600 }}>Validación para: </span>{' '}
                <span>{etiqueta.idioma}</span>
              </Typography>
            </Grid>
            <Grid item size={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>Nombre comercial:</Typography>
            </Grid>
            <Grid item size={12}>
              <Typography
                  sx={{
                      backgroundColor: '#fdecea',
                      color: '#d32f2f',          
                      padding: '8px',            
                      borderRadius: '4px',       
                      marginBottom: '6px',
                      marginTop: '12px'
                  }}
                  >
                  Campos a revisar
                  </Typography>
            </Grid>
            <Grid item size={12}>
              <TableContainer component={Paper} sx={{
                  boxShadow: 'none',
                  border: '1px solid rgba(38, 39, 48, 0.1)',
                  borderRadius: '0.5rem'
                  }}>
                      <Table size="small">
                          <TableHead>
                              <TableRow sx={{ backgroundColor: '#fafafa' }}>
                                  <TableCell sx={{ fontWeight: 'bold'}}>Campo</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>Valor Esperado</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>Valor Etiqueta</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                            {sorted_cols.map((col, col_idx) => {
                              if (etiqueta[data_mapping[col]] !== undefined){
                                if (etiqueta[data_mapping[col]].revision === "CHECK"){
                                  return (
                                    <TableRow key={col_idx}>
                                      <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}>{col}</TableCell>
                                      <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>{etiqueta[data_mapping[col]].reference_value}</TableCell>
                                      <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>{etiqueta[data_mapping[col]].label_value}</TableCell>
                                    </TableRow>
                                  )
                                }
                                else {
                                  return null;
                                }
                              } else {
                                return null;
                              }
                            })}
                          </TableBody>
                      </Table>
                  </TableContainer>
              </Grid>
              <Grid item size={12}>
              <Typography
                  sx={{
                      backgroundColor: 'rgba(33, 195, 84, 0.1)',
                      color: 'rgb(23, 114, 51)',          
                      padding: '8px',            
                      borderRadius: '4px',       
                      marginBottom: '6px',
                      marginTop: '12px'
                  }}
                  >
                  Campos correctos
                  </Typography>
            </Grid>
            <Grid item size={12}>
              <TableContainer component={Paper} sx={{
                  boxShadow: 'none',
                  border: '1px solid rgba(38, 39, 48, 0.1)',
                  borderRadius: '0.5rem'
                  }}>
                      <Table size="small">
                          <TableHead>
                              <TableRow sx={{ backgroundColor: '#fafafa' }}>
                                  <TableCell sx={{ fontWeight: 'bold'}}>Campo</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>Valor Esperado</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>Valor Etiqueta</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                            {sorted_cols.map((col, col_idx) => {
                              if (etiqueta[data_mapping[col]] !== undefined){
                                if (etiqueta[data_mapping[col]].revision === "VALID"){
                                  return (
                                    <TableRow key={col_idx}>
                                      <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}>{col}</TableCell>
                                      <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>{etiqueta[data_mapping[col]].reference_value}</TableCell>
                                      <TableCell sx={{ '&:hover': { backgroundColor: '#f0f0f0' }, borderLeft: '1px solid rgba(38, 39, 48, 0.1)' }}>{etiqueta[data_mapping[col]].label_value}</TableCell>
                                    </TableRow>
                                  )
                                }
                                else {
                                  return null;
                                }
                              } else {
                                return null;
                              }
                            })}
                          </TableBody>
                      </Table>
                  </TableContainer>
              </Grid>
          </>
        )
      })}
    </Grid>
  );
};

export default DisplayValidator;
