import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import { Box, ThemeProvider, Typography, createTheme } from '@mui/material';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "http://72667c05f6ba48f68522d2df6b089e3a@sentry20.pervasive-tech.com:9000/114", 
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, 
});

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const theme = createTheme({
  typography: {
    fontFamily: `'Source Sans Pro', sans-serif`,
  },
  palette: {
    primary: {
      main: "#000",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px', // Vora vermella en focus
          },
        },
      },
    },
  },
});

const root = createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
      {...providerConfig}
    >
      <Sentry.ErrorBoundary fallback={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Alçada completa de la pantalla
          }}
        >
          <Typography variant="h5">Ups! Algo ha fallado. Contacta con el administrador.</Typography>
        </Box>}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </Auth0Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
