import { Link, Typography, Container } from "@mui/material";
import React from "react";

export default function Footer() {
  return (
    <footer
      style={{
        maxHeight: "64px",
        minHeight: "0px",
        position: "fixed",
        left: 0,
        bottom: 0,
        right: 0,
        textAlign: "center",
        padding: "8px",
        backgroundColor: "#9E9E9E",
        zIndex: 1200,
      }}
    >
      <Container fixed sx={{ textAlign: "center", paddingTop: 0, paddingBottom: 0 }}>
        {
          <Typography variant="body2" sx={{ color: "#424242" }}>
            {"Powered by "}
            <Link
              color="inherit"
              target="_blank"
              href="https://www.pervasive-tech.com/"
            >
              Pervasive Technologies S.L.
            </Link>
          </Typography>
        }
      </Container>
    </footer>
  );
}
