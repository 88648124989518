import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navegar a la pàgina principal
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'white',
        textAlign: 'center',
        p: 3,
      }}
    >
      {/* Títol de l'error */}
      <Typography variant="h2" sx={{ fontWeight: 600, marginBottom: 2 }}>
        404
      </Typography>
      
      {/* Missatge informatiu */}
      <Typography variant="h5" sx={{ marginBottom: 4 }}>
        Lo sentimos, la página que buscas no existe.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 6, maxWidth: '600px' }}>
        Es posible que hayas escrito mal la dirección o que la página haya sido movida.
      </Typography>

      {/* Botó per tornar a la pàgina principal */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        sx={{
          fontSize: '1rem',
          fontWeight: 600,
          padding: '12px 24px',
          borderRadius: '8px',
        }}
      >
        Volver a la página principal
      </Button>
    </Box>
  );
};

export default PageNotFound;
